<template>
  <div>
    <div> <p  class="category" style="margin-bottom: 29px;">访问记录</p></div>
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="IP：">
          <el-input v-model="form.keyword" style="width: 150px;"></el-input>
        </el-form-item>
        <el-form-item label="所属来源：">
          <el-select v-model="form.ref" placeholder="请选择"  style="width: 120px;">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="谷歌方式：">
          <el-select v-model="form.seo" placeholder="请选择"  style="width: 120px;">
            <el-option
                v-for="item in option"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="询盘：">
          <el-checkbox v-model="form.url"></el-checkbox>
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker
              v-model="form.datetime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>

    </div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="ID"
          width="180">
        <template slot-scope="scope">
          <span style="" >{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="ip"
          width="180"
          >
        <template slot-scope="scope">
          <span style="" >[{{ scope.row.country}}]{{scope.row.ip}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="访问地址"
          >
        <template slot-scope="scope">
          <span style="">{{ scope.row.url}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="来源"
          >
        <template slot-scope="scope">
          <span style="">{{ scope.row.referrer}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="访问时间"
          width="180">
        <template slot-scope="scope">
          <span style="">{{ scope.row.create_time}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        layout="prev, pager, next"
        :page-size=10
        @current-change="getCate"
        :page-count="total"
        >
    </el-pagination>

  </div>
</template>
<script>
import axios from "axios";
// import Dialog from "@/views/pages/getContentView.vue"


export default {
  name: "AccessView",
  data() {
    return {
      form:{
        datetime:'',
        keyword:'',
        seo:'',
        url:false,
        ref:''
      },
      options:[
        { value: '', label: '所有'},
        { value: 'Google', label: 'Google'},
        { value: 'Youtube', label: 'Youtube'}
      ],
      option:[
        { value: '', label: '所有'},
        { value: 'seo', label: 'SEO'},
        { value: 'sem', label: 'SEM'}
      ],
      tableData: [],
      id: '',
      total: 100,
    }
  },
  components:{
  },
  created() {
    this.getCate();
  },
  methods: {
    goTo(id){
      window.open('/getProduct?id='+id,'_blank');
      // this.$router.push('/getProduct?id='+id)
    },
    onSubmit() {
      this.getCate()
    },
    getCate(page = 1){
      var str = '';
      var ref = this.form.ref;
      var keyword = this.form.keyword;
      var url = this.form.url;
      var seo = this.form.seo;
      const time = this.form.datetime;
      if(ref) str = str + '&ref='+ref
      if(keyword) str = str + '&keyword='+keyword
      if(url) str = str + '&url='+url
      if(seo) str = str + '&seo='+seo
      if(time)  str = str+'&startime='+time[0]+'&endtime='+time[1];
      axios.get('/api/Access/index.html?page='+page+str).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
          this.total = res.data.total
        }
      });
    }
  }
};
</script>
<style scoped lang=''>
</style>