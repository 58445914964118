<template>
<el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="网站标题">
      <el-input v-model="form.title"></el-input>
    </el-form-item>
    <el-form-item label="网站链接">
      <el-input v-model="form.website"></el-input>
    </el-form-item>
    <el-form-item label="关键词">
      <el-input v-model="form.keyword" placeholder=""></el-input>
    </el-form-item>
    <el-form-item label="简介">
      <el-input v-model="form.info"></el-input>
    </el-form-item>
    <el-form-item label="首页标题">
      <el-input v-model="form.copyright"></el-input>
    </el-form-item>
    <el-form-item label="公司地址">
      <el-input v-model="form.address"></el-input>
    </el-form-item>
    <el-form-item label="公司邮编">
      <el-input v-model="form.post"></el-input>
    </el-form-item>
    <el-form-item label="公司热线">
      <el-input v-model="form.phone"></el-input>
    </el-form-item>
    <el-form-item label="公司传真">
      <el-input v-model="form.fax"></el-input>
    </el-form-item>
    <el-form-item label="电子邮件">
      <el-input v-model="form.email"></el-input>
    </el-form-item>
    <el-form-item label="其他信息">
      <el-input v-model="form.other"></el-input>
    </el-form-item>
<!--    <el-form-item label="页眉代码">-->
<!--      <el-input v-model="form.top"></el-input>-->
<!--    </el-form-item>-->
<!--    <el-form-item label="页脚代码">-->
<!--      <el-input v-model="form.footer"></el-input>-->
<!--    </el-form-item>-->

<!--    <el-form-item label="网站图标">-->
<!--      <el-input v-model="form.icon"></el-input>-->
<!--    </el-form-item>-->
    <el-form-item label="网站LOGO">
      <el-upload
          class="upload-demo"
          action="/api/Upload/upload.html"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="handleAvatarSuccess"
          :file-list="form.fileList"
          list-type="picture"
          name="image[]"
      >
        <el-button size="sma
        ll" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
          只能上传jpg/png文件，且不超过2Mb
        </div>
      </el-upload>
      <el-input v-model="form.logo" type="hidden"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">保存</el-button>
<!--      <el-button>取消</el-button>-->
    </el-form-item>
  </el-form>
</template>
<script>
import axios from "axios";

export default {
  name: "WebView",
  data() {
    return {
      form: {
        name: "",
        website: "",
        logo: "",
        icon: "",
        keyword: "",
        copyright: "",
        address: "",
        post: "",
        phone: "",
        fax: "",
        email: "",
        other: "",
        top: "",
        footer: "",
        fileList: [],
      },

      options: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: [],
    };
  },
  created() {
    this.getBase();
  },
  methods: {
    getBase(){
      axios.get('/api/Base/index.html').then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }

      });
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.logo = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onSubmit() {
        this.axios({
          method:'post',
          url:'/api/Base/setBase.html',
          data:this.form
        }).then((res)=>{
          if(res.data.code != 200) {
            alert(res.data.msg);
          }
          else{
            alert(res.data.msg);
          }
        })
    },
  },
};
</script>
<style scoped lang='scss'>
.el-input-number {
  margin-left: 10px;
}
</style>